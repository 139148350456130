import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SliceList from "../components/sliceList"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query jobPageQuery($id: String, $lang: String!) {
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicJobPost(id: { eq: $id }) {
      ...PrismicJobPostFragment
    }
    prismicSocial(lang: { eq: $lang }) {
      ...PrismicSocialFragment
    }
  }
`

const RenderBody = ({ data }) => {
  return (
    <div className="text-lg">
      <div className="max-w-4xl mx-auto sm:pt-16 px-4 sm:px-6">
        <div className="border-b pb-4">
          <div className="text-base font-semibold uppercase tracking-wider text-gray-700 my-3">
            Unicard Careers
          </div>
          {data.page_title && (
            <div className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
              {data.page_title}
            </div>
          )}
          {data.job_introduction && (
            <div className="my-3 text-2xl ">{data.job_introduction}</div>
          )}
          <div className="my-3 text-blue-600 text-xl">
            {data.contract_type} - {data.location}
          </div>
        </div>
      </div>
      {data.body !== null &&
        data.body.length > 0 &&
        data.body.map((slice, index) => {
          return (
            <div
              className="max-w-4xl mx-auto md:pt-6 relative"
              key={`slice-${index}`}
            >
              <SliceList slice={slice} />
            </div>
          )
        })}
    </div>
  )
}

const JobTemplate = ({ data, pageContext }) => {
  const pageData = data.prismicJobPost.data
  const navigation = data.prismicNavigation
  const social = data.prismicSocial

  let ogImage
  for (const image in pageData.body) {
    if (pageData.body[image].slice_type === "image") {
      ogImage = pageData.body[image].items[0].image.url
      break
    }
  }

  return (
    <Layout
      altLang={data.prismicJobPost.alternate_languages}
      navigation={navigation}
      social={social}
    >
      <Seo
        title={pageData.meta_title ? pageData.meta_title : pageData.title}
        keywords={pageData.meta_keywords}
        description={pageData.meta_description}
        lang={pageContext.lang}
        image={ogImage}
      />
      <RenderBody data={pageData} />
    </Layout>
  )
}

export default withPreview(JobTemplate)
